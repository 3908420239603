import { Spinner } from "@nextui-org/react";

export default function Loading({ classNames }) {
  // You can add any UI inside Loading, including a Skeleton.
  return (
    <Spinner
      className={`h-full fixed left-0 bottom-0 top-0 right-0 backdrop-blur-md  bg-white z-50 ${
        classNames ? classNames : ""
      }`}
    />
  );
}
