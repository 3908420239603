export const leadTemplate = (data) => {
  const {type, name, email, phone, description, message} = data;

  return `
<html lang="en">
<head>
  <meta charset="UTF-8">
  <style>
    /* Basic styling for the email */
    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f4f4f4;
    }
    .email-container {
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      background-color: #ffffff;
      padding: 20px;
      border-radius: 8px;
      border: 1px solid #ddd;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    .email-header {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
      color: #0096ac;
    }
    .email-table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
    }
    .email-table td {
      border: 1px solid;
      border-color:#0096ac;
      padding: 12px;
      text-align: left;
    }
    .email-table th {
      background-color: #0096ac;
      color: #ffffff;
      font-weight: bold;
      border: 1px solid;
      border-color: #0096ac;
      border-bottom-color: #fff !important;
      max-width: 100%;
      width: 130px;
      text-align: left;
      padding: 12px;
    }
    .email-table td {
      background-color: #ffffff;
      color: #000000;
    }
    .note {
      font-size: 14px;
      color: #555;
      margin-top: 20px;
      text-align: center;
    }
  </style>
</head>
<body>
  <div class="email-container">
    <div class="email-header">
      ${type} form submission
    </div>
    <table class="email-table">
      <tr>
        <th>Name</th>
        <td>${name}</td>
      </tr>
      <tr>
        <th>Email</th>
        <td>${email}</td>
      </tr>
      <tr>
        <th>Phone Number</th>
        <td>${phone}</td>
      </tr>
      <tr>
        <th style="border-bottom-color:#0096ac !important">Message</th>
        <td>${message || description}</td>
      </tr>
    </table>
    
  </div>
</body>
</html>
    `
};
