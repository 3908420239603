"use client";
import React, { useState } from "react";
import { ContactForm, SendBtn, SubTitle } from "../../../components/molecules";
import { useForm } from "react-hook-form";
import { submitContactForm } from "../../../actions/contact";
import ContactFormShape from "../../../assets/svg/ContactFormShape";
import { useDictionary } from "../../../context/dictionaryProvider";
import { bitrixLeadAction } from "../../../actions/bitrix";
import { bitrixSourceIds, senderEmail } from "../../../lib/util";
import InputTextField from "../../../components/atoms/InputTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loading from "../loading";
import ThankuModal from "../../../components/modal/ThankuModal";
import {
  Modal,
  ModalBody,
  ModalContent,
  useDisclosure,
} from "@nextui-org/react";
import { isPhoneValid } from "../../../lib/validatePhone";
import PhoneField from "../../../components/atoms/PhoneField";
import { leadTemplate } from "../../../emailTemplates/leadTemplate";
import { emailAction } from "../../../actions/email";
import { useParams } from "next/navigation";

const ContactWrapShape = (classNames) => {
  return (
    <svg
      width="144"
      height="86"
      viewBox="0 0 144 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="contactWrapShape"
    >
      <path
        d="M10.2056 75.7627C10.2056 91.6071 -12.195 89.661 -23 90L148.563 90L148.563 1.66558e-06C148.563 8.61026 139.131 13.7606 131.785 16.1095C128.793 17.066 125.635 17.2881 122.494 17.2881L42.2056 17.2881C24.5325 17.2881 10.2056 31.615 10.2056 49.2881L10.2056 75.7627Z"
        fill="white"
        stroke="black"
      />
    </svg>
  );
};

const formSchema = yup
  .object({
    name: yup
      .string()
      .required()
      .min(3, "Name must be at least 3 characters long")
      .max(25, "Name cannot exceed 25 characters")
      .matches(/^[a-zA-Z0-9\s]+$/, "No special character allowed"),
    email: yup
      .string()
      .email()
      .required()
      .matches(
        /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Email must have a valid domain"
      ),
    phone: yup
      .string()
      .required("Phone number is required")
      .test("is-valid-phone", "Phone number is not valid", (value) => {
        if (!value) return false; // Handle empty value if required
        return isPhoneValid(value);
      }),
    description: yup
      .string()
      .trim()
      .required()
      .matches(/^[a-zA-Z0-9\s]+$/, "No special character allowed"),
  })
  .required();

const UserContactForm = ({
  title,
  showformShape = false,
  btnText,
  btnStyle,
  searchParams,
  id = "contact-us",
}) => {
  const params = useParams();
  const dict = useDictionary();
  const [response, setResponse] = useState("");
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors, isSubmitting, isDirty },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const [Thankyou, setThankyou] = useState(false);

  const onSubmit = async (data) => {
    try {
      const res = await submitContactForm({
        ...data,
        phone: data?.phone?.toString(),
      });
      await bitrixLeadAction(
        { ...data, message: data?.description },
        searchParams,
        bitrixSourceIds.contactUs
      );

      try {
        const html = leadTemplate({ type: "Contact us", ...data });
        await emailAction(senderEmail, html);
      } catch (error) {
        console.log(`Error sending email`, error?.message);
      }

      if (res?.error) setResponse("Contact submit failed!");
      reset();
      onOpenChange();
    } catch (error) {
      console.log(`Error submitting form: submitContactForm`, error?.message);
    }
  };
  const splitName = (fullName) => {
    const nameParts = fullName.trim().split(" ");
    return {
      firstName: nameParts[0] || "",
      lastName: nameParts.slice(1).join(" ") || "",
    };
  };
  const trackContactFormClick = (menuName, name, email, phone, message) => {
    const { firstName, lastName } = splitName(name);
    const userId = webengage.state.getForever().luid
    webengage.user.login(email);
    webengage.user.setAttribute("we_email", email);
    webengage.user.setAttribute("we_last_name", lastName);
    webengage.user.setAttribute("we_first_name", firstName);
    webengage.user.setAttribute("we_phone", phone);

    webengage.track('UserContactForm', {
      category: 'UserContactForm',
      label: menuName,
      value: 1,
      userName: name, 
      userEmail: email, 
      userPhone: phone,
      userMessage: message, 
    });
  }; 

  return (
    <>
      <ContactForm className="relative ">
        {showformShape && (
          <div className={`absolute z-20  2xl:-top-20  ${params?.lang === "ar" ? "-top-16 -left-8 2xl:-left-20 right-auto" : "-right-16 2xl:-right-20 left-auto"}`}>
            <ContactFormShape classNames={`${params?.lang === "ar" ? "w-32 h-32" : ""}`}/>
          </div>
        )}
        <>
          {!isSubmitting ? (
            <form
              className="relative bg-[#fff]"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div
                className="space-y-12 px-12 pb-10"
                style={{ border: "1px solid", borderRadius: "16px" }}
              >
                <div className="pb-12">
                  {title && (
                    <p className="my-10 text-[40px] leading-[40px] text-black font-extrabold ">
                      {title}
                    </p>
                  )}
                  <div className="mt-10">
                    <div className="mt-2">
                      <InputTextField
                        type={"text"}
                        label={dict?.form?.fieldNames.name}
                        placeholder={dict?.form?.placeholders?.typeHere}
                        isInvalid={!!errors?.name}
                        errorMessage={errors?.name?.message}
                        {...register("name", { required: true })}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-1 mb-8">
                    <div className="mt-2">
                      <PhoneField
                        label={dict?.form.fieldNames.phone}
                        value={getValues("phone")}
                        onChange={async (value) => {
                          setValue("phone", value, {
                            shouldDirty: true,
                            shouldValidate: isDirty,
                          });
                        }}
                        isInvalid={!!errors?.phone}
                        errorMessage={errors?.phone?.message}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-1 mb-8">
                    <div className="mt-2">
                      <InputTextField
                        label={dict?.form?.fieldNames.email}
                        type={"email"}
                        isInvalid={!!errors?.email}
                        errorMessage={errors?.email?.message}
                        {...register("email", { required: true })}
                        aria-invalid={errors.email ? "true" : "false"}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-1 mb-8">
                    <div className="mt-2">
                      <InputTextField
                        label={dict?.form?.fieldNames.descriptionTitle}
                        placeholder={dict?.form?.placeholders?.typeHere}
                        type={"description"}
                        isInvalid={!!errors.description}
                        errorMessage={errors.description?.message}
                        {...register("description", { required: true })}
                        aria-invalid={errors.description ? "true" : "false"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <p className="font-semibold text-sm text-[#008b3d] !m-0">
                {response && response}
              </p>

              <SendBtn
                type="submit"
                style={btnStyle}
                disabled={isSubmitting}
                id={id}
                padding="13px 30px"
                onClick={() => {
                  trackContactFormClick(
                    title || 'User Contact Form',
                    getValues().name,
                    getValues().email,
                    getValues().phone,
                    getValues().description
                  );
                }}
              >
                {btnText || dict?.submit}
              </SendBtn>
              <ContactWrapShape />
            </form>
          ) : (
            <Loading />
          )}
        </>
      </ContactForm>
      {isOpen && (
        <Modal
          backdrop="blur"
          isOpen={isOpen}
          placement="center"
          size="xl"
          classNames={{
            backdrop: "blur-md",
            placement: "center",
          }}
          scrollBehavior="outside"
          onOpenChange={onOpenChange}
          onClose={reset}
        >
          <ModalContent>
            <ModalBody>
              <ThankuModal
                message={
                  "Thank you for submitting your query! Our team will get back to you soon!"
                }
                btnText={"Close"}
                handleClick={() => {
                  onOpenChange();
                  setThankyou((prev) => !prev);
                }}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default UserContactForm;
